import { useQuery } from '@tanstack/react-query'
import { CustomerProfile, CustomerApi, ErrorResponse } from '@citruscamps/citrus-client'
import { generateApiConfig } from '../../../utils/client-config'
import { generateItemKey } from '../../../utils/key-generator'
import { useRequestHandler } from '../../../hooks/useRequestHandler'
import { useAuth } from '../../../hooks/useAuth'

interface IProps {
  programId?: string
  redirect?: boolean
  initialData?: CustomerProfile
}

interface FetchedData {
  data?: CustomerProfile
  isSuccess: boolean
  isLoading: boolean
  isError: boolean
  error: ErrorResponse | null
}

export const useFetchCustomerProfile = ({
  programId,
  redirect,
  initialData,
}: IProps = {}): FetchedData => {
  const { user } = useAuth()
  const { requestHandler } = useRequestHandler()
  const queryKeys = generateItemKey({
    type: 'contact',
    id: programId,
  })
  const {
    data,
    error,
    isError,
    isInitialLoading: isLoading,
    isSuccess,
  } = useQuery<CustomerProfile, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new CustomerApi(generateApiConfig())
      return await requestHandler<CustomerProfile>(
        () => client.findOneCustomerProfile({ program_id: programId }, { signal }),
        {
          redirect: typeof redirect === 'undefined' || redirect === true,
        },
      )
    },
    { enabled: !!user && !!programId, retry: false, initialData, refetchOnWindowFocus: false },
  )

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    error,
  }
}
