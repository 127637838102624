import * as queryString from 'query-string'

export const toQueryParams = (obj: any): string => {
  return queryString.stringify(obj, { arrayFormat: 'none', sort: (a, b) => (a > b ? 1 : 0) })
}

export const fromQueryParams = (value: string) => queryString.parse(value)

export const toSingleQueryValue = (
  q: Record<string, string | string[] | null | undefined>,
): Record<string, string> => {
  let i: Record<string, string> = {}
  Object.keys(q).forEach(
    (k) =>
      (i[k] = (typeof q[k] === 'string' ? q[k] : Array.isArray(q[k]) ? q[k]?.[0] : q[k]) as
        | string
        | ''),
  )
  return i
}
