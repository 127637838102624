/* eslint-disable no-empty */
import { useState } from 'react'
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../utils/local-storage'

export function useLocalStorage<T>(
  keyName: string,
  initialValue?: T,
  overwrite?: boolean,
): [T, (t: T) => void] {
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>((): T => {
    try {
      if (overwrite && initialValue) {
        setLocalStorageItem(keyName, initialValue)
      }
      return getLocalStorageItem(keyName) || initialValue
    } catch (error) {
      return initialValue as T
    }
  })
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (keyValue?: T): void => {
    // Allow value to be a function so we have same API as useState
    const valueToStore = keyValue instanceof Function ? keyValue(storedValue) : keyValue
    // Save state
    setStoredValue(valueToStore)
    // Save to local storage
    try {
      if (valueToStore) {
        setLocalStorageItem(keyName, valueToStore)
      } else {
        removeLocalStorageItem(keyName)
      }
    } catch {}
  }
  return [storedValue, setValue]
}
