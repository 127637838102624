import {
  AccountApi,
  CreateUser,
  Customer,
  CustomerProfile,
  ErrorResponse,
  Login,
  SuccessResponse,
  User,
} from '@citruscamps/citrus-client'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useFetchCustomer } from '../components/CustomerProfileView/hooks/useFetchCustomer'
import { useFetchCustomerProfile } from '../components/CustomerProfileView/hooks/useFetchCustomerProfile'
import translations from '../translations/en.json'
import { generateApiConfig } from '../utils/client-config'
import { generateBaseKey } from '../utils/key-generator'
import { useAuth } from './useAuth'
import { useRequestHandler } from './useRequestHandler'
import { useToast } from './useToast'

interface IProps {
  programId?: string
  redirect?: boolean
}

export interface FetchedUserData {
  user?: User
  customer?: Customer
  customerProfile?: CustomerProfile
  isLoading: boolean
  isFetched: boolean
  isSuccess: boolean
  isError: boolean
  error: ErrorResponse | null
  hasSentCode: boolean
  handleSignUp: (values: CreateUser) => Promise<any>
  handleLogin: (values: Login) => Promise<void>
  handleResendVerifyEmail: () => Promise<void>
}

export const useFetchUserDetails = ({ programId, redirect }: IProps = {}): FetchedUserData => {
  const { requestHandler } = useRequestHandler()
  const queryClient = useQueryClient()
  const { setToast } = useToast()
  const [hasSentCode, setHasSentCode] = useState<boolean>(false)
  const {
    user,
    isLoading: isLoadingAccount,
    isFetched: isAccountFetched,
    isError: isAccountError,
    isSuccess: isAccountSuccess,
    error: accountError,
    handleLogin,
    handleSignUp,
    handleUpdateUser,
  } = useAuth()
  const {
    data: customer,
    isSuccess: isCustomerSuccess,
    isLoading: isLoadingCustomer,
    isError: isCustomerError,
    error: customerError,
  } = useFetchCustomer({
    redirect,
  })
  const {
    data: customerProfile,
    isSuccess: isCustomerProfileSuccess,
    isLoading: isLoadingCustomerProfile,
    isError: isCustomerProfileError,
    error: profileError,
  } = useFetchCustomerProfile({
    programId,
    redirect,
  })

  const handleResendVerifyEmail = async () => {
    try {
      const accountClient = new AccountApi(generateApiConfig())
      await requestHandler<SuccessResponse>(() => accountClient.reverifyEmailAccount(), {
        redirect: false,
      })
      await queryClient.invalidateQueries(generateBaseKey({ type: 'user' }))
    } catch (e: any) {
      setToast({
        type: 'danger',
        header: (translations as any)['error.default_toast_header'],
        message: e.message || (translations as any)['error.default_toast_message'],
      })
    }
  }

  return {
    user,
    customer,
    customerProfile,
    isLoading:
      (isLoadingAccount && !user) ||
      (isLoadingCustomer && !customer) ||
      (isLoadingCustomerProfile && !customerProfile),
    isFetched: isAccountFetched,
    isSuccess: isAccountSuccess && isCustomerSuccess && isCustomerProfileSuccess,
    isError: isAccountError || isCustomerError || isCustomerProfileError,
    error: accountError || customerError || profileError || null,
    hasSentCode,
    handleResendVerifyEmail,
    handleLogin: async (...args) => {
      try {
        const token = await handleLogin(...args)
        await queryClient.invalidateQueries(generateBaseKey({ type: 'customer' }))
        await queryClient.invalidateQueries(generateBaseKey({ type: 'contact' }))
        return token
      } catch (e: any) {
        setToast({
          type: 'danger',
          header: (translations as any)['error.default_toast_header'],
          message: e.message || (translations as any)['error.default_toast_message'],
        })
        throw e
      }
    },
    handleSignUp: async (value: CreateUser) => {
      try {
        if (!user) {
          const token = await handleSignUp(value)
          setHasSentCode(true)
          await queryClient.invalidateQueries(generateBaseKey({ type: 'customer' }))
          await queryClient.invalidateQueries(generateBaseKey({ type: 'contact' }))
          return token
        } else {
          const _user = await handleUpdateUser({
            ...user,
            first_name: value.first_name,
            last_name: value.last_name,
            email: value.email,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          } as User)
          await handleResendVerifyEmail()
          return _user
        }
      } catch (e: any) {
        setToast({
          type: 'danger',
          header: (translations as any)['error.default_toast_header'],
          message: e.message || (translations as any)['error.default_toast_message'],
        })
        throw e
      }
    },
  }
}
