import {
  ExploreEventFromJSONTyped,
  ExploreEventListFromJSONTyped,
  ExploreMembershipPackageListFromJSONTyped,
  ExploreProgramFromJSONTyped,
  ExploreSessionPackageListFromJSONTyped,
  VenueListFromJSONTyped,
} from '@citruscamps/citrus-client'
import { DehydratedState, InfiniteData, QueryKey } from '@tanstack/react-query'
import { ParsedUrlQuery } from 'querystring'
import { DefaultMembershipPackageFetchProps } from '../components/Packages/hooks/useFetchMemberPackages'
import { DefaultSessionPackageFetchProps } from '../components/Packages/hooks/useFetchSessionPackages'
import { UnlimitedPagination } from '../constants/pagination'
import { generateItemKey, generateListKey } from '../utils/key-generator'
import { toSingleQueryValue } from './query-params'

const hasInfiniteDataPages = (data: any): data is InfiniteData<any> => 'pages' in data

interface StateTypedOptions {
  query: ParsedUrlQuery
  state: unknown
}

export const getDehydratedStateTyped = ({ query, state }: StateTypedOptions): DehydratedState => {
  const { mutations = [], queries = [] }: Partial<DehydratedState> = state || {}
  const { event_id: eventId, program_id: programId } = toSingleQueryValue(query)
  const eventKey: QueryKey | undefined = eventId
    ? generateItemKey({ type: 'program_event', id: eventId })
    : undefined
  const programKey: QueryKey | undefined = programId
    ? generateItemKey({ type: 'program', id: programId })
    : undefined
  const mFetchProps = DefaultMembershipPackageFetchProps
  const mPackageQueryKeys: QueryKey | undefined = programId
    ? generateListKey({
        type: 'membership_package',
        pagination: UnlimitedPagination,
        sort: mFetchProps.sort,
        order: mFetchProps.order,
        query: { program_id: programId, ...mFetchProps.filter },
      })
    : undefined
  const sFetchProps = DefaultSessionPackageFetchProps
  const sPackageQueryKeys: QueryKey | undefined = programId
    ? generateListKey({
        type: 'session_package',
        pagination: UnlimitedPagination,
        sort: sFetchProps.sort,
        order: sFetchProps.order,
        query: { program_id: programId, ...sFetchProps.filter },
      })
    : undefined
  const venueQueryKeys = generateItemKey({
    type: 'venue',
    id: programId,
  })
  const eventsQueryKeys = generateListKey({
    type: 'program_event',
    pagination: UnlimitedPagination,
    sort: undefined,
    order: undefined,
  })
  const dehydratedStateTyped = {
    mutations,
    queries: queries.map((dehydratedQuery) => {
      const hasData = !!dehydratedQuery.state.data
      try {
        const isEventQuery = dehydratedQuery.queryKey.join(':') === eventKey?.join(':') && hasData
        if (isEventQuery) {
          return {
            ...dehydratedQuery,
            state: {
              ...dehydratedQuery.state,
              data: ExploreEventFromJSONTyped(dehydratedQuery.state.data, true),
            },
          }
        }
        const isProgramQuery =
          dehydratedQuery.queryKey.join(':') === programKey?.join(':') && hasData
        if (isProgramQuery) {
          return {
            ...dehydratedQuery,
            state: {
              ...dehydratedQuery.state,
              data: ExploreProgramFromJSONTyped(dehydratedQuery.state.data, true),
            },
          }
        }
        const stateData: InfiniteData<any> | undefined = hasInfiniteDataPages(
          dehydratedQuery.state.data,
        )
          ? dehydratedQuery.state.data
          : undefined
        if (stateData) {
          const isMembershipPackageQuery =
            dehydratedQuery.queryKey.join(':') === mPackageQueryKeys?.join(':')
          if (isMembershipPackageQuery) {
            return {
              ...dehydratedQuery,
              state: {
                ...dehydratedQuery.state,
                data: {
                  ...stateData,
                  pages: stateData.pages.map((page) =>
                    ExploreMembershipPackageListFromJSONTyped(page, true),
                  ),
                },
              },
            }
          }
          const isSessionPackageQuery =
            dehydratedQuery.queryKey.join(':') === sPackageQueryKeys?.join(':')
          if (isSessionPackageQuery) {
            return {
              ...dehydratedQuery,
              state: {
                ...dehydratedQuery.state,
                data: {
                  ...stateData,
                  pages: stateData.pages.map((page) =>
                    ExploreSessionPackageListFromJSONTyped(page, true),
                  ),
                },
              },
            }
          }
          const isVenueQuery = dehydratedQuery.queryKey.join(':') === venueQueryKeys?.join(':')
          if (isVenueQuery) {
            return {
              ...dehydratedQuery,
              state: {
                ...dehydratedQuery.state,
                data: VenueListFromJSONTyped(dehydratedQuery.state.data, true),
              },
            }
          }
          const isEventsQuery =
            dehydratedQuery.queryKey?.slice(0, 1).join(':') ===
            eventsQueryKeys?.slice(0, 1)?.join(':')
          if (isEventsQuery) {
            return {
              ...dehydratedQuery,
              state: {
                ...dehydratedQuery.state,
                data: {
                  ...stateData,
                  pages: stateData.pages.map((page) => ExploreEventListFromJSONTyped(page, true)),
                },
              },
            }
          }
        }
      } catch (e) {
        console.error(e)
      }
      return dehydratedQuery
    }),
  }
  return dehydratedStateTyped
}
