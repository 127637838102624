import {
  AccountBalanceObjectType,
  AppointmentOptionObjectType,
  AppointmentTypeObjectType,
  AttachmentObjectType,
  AttendeeObjectType,
  ContactObjectType,
  CustomerAddressObjectType,
  CustomerObjectType,
  DiscountObjectType,
  ExploreProductObjectType,
  FeaturesObjectType,
  FeedbackObjectType,
  FieldChoiceObjectType,
  FieldObjectType,
  FileObjectType,
  FormResponseObjectType,
  MembershipObjectType,
  MembershipPackageObjectType,
  MessageObjectType,
  NotificationObjectType,
  NotificationPreferencesObjectType,
  OrderItemObjectType,
  OrderObjectType,
  PaymentObjectType,
  PaymentSourceObjectType,
  ProductCategoryObjectType,
  ProductObjectType,
  ProductVariationObjectType,
  ProgramAttendeeObjectType,
  ProgramEventObjectType,
  ProgramEventSeriesObjectType,
  ProgramObjectType,
  QuickFilterObjectType,
  RefundPolicyObjectType,
  RegistrationObjectType,
  RegistrationOptionObjectType,
  RelationObjectType,
  SessionCreditBalanceObjectType,
  SessionPackageObjectType,
  StockImageObjectType,
  TaxItemObjectType,
  TaxRateObjectType,
  UserObjectType,
  VenueObjectType,
} from '@citruscamps/citrus-client'
import { QueryKey } from '@tanstack/react-query'
import {
  EventTagsObjectType,
  EventWeekObjectType,
  ScheduledEventObjectType,
} from '../interfaces/events'
import { Pagination } from '../interfaces/pagination'

type ObjectType =
  | AccountBalanceObjectType
  | AppointmentOptionObjectType
  | AppointmentTypeObjectType
  | AttachmentObjectType
  | AttendeeObjectType
  | ContactObjectType
  | CustomerAddressObjectType
  | CustomerObjectType
  | DiscountObjectType
  | EventTagsObjectType
  | EventWeekObjectType
  | ExploreProductObjectType
  | FeaturesObjectType
  | FeedbackObjectType
  | FieldChoiceObjectType
  | FieldObjectType
  | FileObjectType
  | FormResponseObjectType
  | MembershipObjectType
  | MembershipPackageObjectType
  | MessageObjectType
  | NotificationObjectType
  | NotificationPreferencesObjectType
  | OrderItemObjectType
  | OrderObjectType
  | PaymentObjectType
  | PaymentSourceObjectType
  | ProductCategoryObjectType
  | ProductObjectType
  | ProductVariationObjectType
  | ProgramAttendeeObjectType
  | ProgramEventObjectType
  | ProgramEventSeriesObjectType
  | ProgramObjectType
  | QuickFilterObjectType
  | RefundPolicyObjectType
  | RegistrationObjectType
  | RegistrationOptionObjectType
  | RelationObjectType
  | ScheduledEventObjectType
  | SessionCreditBalanceObjectType
  | SessionPackageObjectType
  | StockImageObjectType
  | TaxItemObjectType
  | TaxRateObjectType
  | UserObjectType
  | VenueObjectType
  | 'membership_balance'
  | 'available_date'
  | 'available_time'
  | 'country'
  | 'region'
  | 'add_credits'
  | 'geocoder'
  | 'program_categories'
  | 'ip_address'
  | 'geolocation'
  | 'quote'
  | 'unsubscribe'

interface IGenerateBaseKeyProps {
  type: ObjectType
  query?: unknown
}

export const generateBaseKey = (props: IGenerateBaseKeyProps): QueryKey => {
  const { type, query } = JSON.parse(JSON.stringify(props))
  const keys: QueryKey = [type]
  return query ? keys.concat(query) : keys
}

interface IGenerateItemKeyProps extends IGenerateBaseKeyProps {
  id: string | undefined
}

export const generateItemKey = (props: IGenerateItemKeyProps): QueryKey => {
  const { type, id, query } = JSON.parse(JSON.stringify(props))
  const keys: QueryKey = [type, id].filter(Boolean)
  return query ? keys.concat(query) : keys
}

interface IGenerateListKeyProps extends IGenerateBaseKeyProps {
  pagination: Pagination
  sort: string | undefined
  order: 'ASC' | 'DESC' | undefined
  search?: string
}

export const generateListKey = (props: IGenerateListKeyProps): QueryKey => {
  const {
    type,
    pagination,
    sort,
    order,
    query = {},
    search = '',
  } = JSON.parse(JSON.stringify(props))
  return [type, pagination, sort, order, query, search]
}
