const keyPrefix = 'citrus'

export const getBase64Key = (keyName: string): string => {
  if (typeof window === 'undefined') {
    return Buffer.from(unescape(encodeURIComponent(`${keyPrefix}-${keyName}`)), 'ascii').toString(
      'base64',
    )
  }
  return btoa(unescape(encodeURIComponent(`${keyPrefix}-${keyName}`)))
}

export function getBase64Value<T = any>(keyValue: T): string {
  let value = ''
  try {
    if (keyValue && typeof keyValue !== 'string') {
      value = JSON.stringify(keyValue)
    } else if (typeof keyValue === 'string') {
      value = keyValue
    }
  } catch {
    if (typeof keyValue === 'string') {
      value = keyValue
    } else {
      value = ''
    }
  }
  if (typeof window === 'undefined') {
    return Buffer.from(unescape(encodeURIComponent(value)), 'ascii').toString('base64')
  }
  return btoa(unescape(encodeURIComponent(value)))
}

export function getObjectValue<T = any>(base64Value?: string): T | null {
  if (!base64Value) return null
  const b = base64Value
    ? typeof window === 'undefined'
      ? decodeURIComponent(escape(Buffer.from(base64Value, 'base64').toString('ascii')))
      : decodeURIComponent(escape(atob(base64Value)))
    : null
  let value: T | null = null
  try {
    if (b) {
      value = JSON.parse(b)
    }
  } catch {
    value = null
  }
  return value
}

export const getLocalStorageItem = (keyName: string, version: string = '2021-03-04'): any => {
  const name = getBase64Key(keyName)
  const value = window.localStorage.getItem(name) || undefined
  const obj = getObjectValue(value)
  return obj?.version === version ? obj.value : null
}

export const setLocalStorageItem = (
  keyName: string,
  keyValue?: any,
  version: string = '2021-03-04',
): void => {
  const name = getBase64Key(keyName)
  const value = getBase64Value({ value: keyValue, version })
  window.localStorage.setItem(name, value)
}

export const removeLocalStorageItem = (keyName: string): void => {
  const name = getBase64Key(keyName)
  window.localStorage.removeItem(name)
}
