import {
  Customer,
  CustomerApi,
  CustomerMessage,
  CustomerMessageCreateParams,
  CustomerProfile,
  ErrorResponse,
  SuccessResponse,
} from '@citruscamps/citrus-client'
import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import translations from '../translations/en.json'
import { generateApiConfig, generateBaseUrl } from '../utils/client-config'
import { HttpClient } from '../utils/http'
import { useProgram } from './useFetchProgram'
import { useFetchUserDetails } from './useFetchUserDetails'
import { useRequestHandler } from './useRequestHandler'
import { useToast } from './useToast'

export interface ISupportParams extends CustomerMessageCreateParams {
  type: 'citrus' | 'program'
  issue_type?: 'billing' | 'bug' | 'suggest_feature' | 'tech_support'
}

const SupportContext: React.Context<SupportProvider> = createContext<SupportProvider>({
  isShown: false,
  isError: false,
  isSuccess: true,
  error: null,
  message: {} as any,
  customer: undefined,
  customerProfile: undefined,
  isLoadingCustomer: true,
  setIsShown: () => {
    throw new Error('Not implemented yet')
  },
  setMessage: () => {
    throw new Error('Not implemented yet')
  },
  handleSubmit: async () => {
    throw new Error('Not implemented yet')
  },
})

export const ProvideSupport = ({ children }: React.PropsWithChildren<any>): React.ReactElement => {
  const support: SupportProvider = useProvideSupport()
  return <SupportContext.Provider value={support}>{children}</SupportContext.Provider>
}

export const useSupport = (): SupportProvider => {
  return useContext<SupportProvider>(SupportContext)
}

export interface SupportProvider {
  isShown: boolean
  isError: boolean
  isSuccess: boolean
  error: ErrorResponse | null
  message: ISupportParams
  customer?: Customer
  customerProfile?: CustomerProfile
  isLoadingCustomer: boolean
  setIsShown: Dispatch<SetStateAction<boolean>>
  setMessage: Dispatch<SetStateAction<ISupportParams>>
  handleSubmit: (values: ISupportParams) => Promise<any>
}

export const useProvideSupport = (): SupportProvider => {
  const { requestHandler } = useRequestHandler()
  const { setToast } = useToast()
  const { program } = useProgram()
  const [isShown, setIsShown] = useState<boolean>(false)
  const [message, setMessage] = useState<ISupportParams>({
    type: 'program',
    program_id: program?.id || '',
    medium: 'email',
    subject: '',
    body: '',
  })
  const [error, setError] = useState<ErrorResponse | null>(null)
  const {
    customer,
    customerProfile,
    isLoading: isLoadingCustomer,
  } = useFetchUserDetails({
    programId: program?.id,
    redirect: false,
  })

  const handleSubmit = async (values: ISupportParams): Promise<void> => {
    if (program?.id && values) {
      try {
        setError(null)
        if (values.type === 'program') {
          const client = new CustomerApi(generateApiConfig())
          const _message = await requestHandler<CustomerMessage>(
            () =>
              client.createCustomerMessage({
                CustomerMessageCreateParams: {
                  order_id: values.order_id,
                  registration_id: values.registration_id,
                  program_id: program.id,
                  medium: values.medium,
                  subject: values.subject,
                  body: values.body,
                  new_customer_profile: customerProfile ? undefined : values.new_customer_profile,
                  context: values.context,
                },
              }),
            {
              toast: {
                setToast,
                error: (translations as any)['error.default_toast_message'],
              },
            },
          )
          setMessage({ ..._message, type: values.type })
        } else if (values.type === 'citrus') {
          const client = new HttpClient({ baseUrl: generateBaseUrl() || '/' })
          let headers: any = {}
          await requestHandler<SuccessResponse>(() =>
            client.post(
              '/api/v1/support',
              {
                reporter_name: [
                  values.new_customer_profile?.first_name,
                  values.new_customer_profile?.last_name,
                ].join(' '),
                reporter_email: values.new_customer_profile?.email,
                type: values.issue_type,
                summary: values.subject,
                description: values.body,
                customer_id: customer?.id,
                program_id: program.id,
                program_name: program?.name,
                url: window.location.href,
                app_version: process.env.REACT_APP_VERSION,
              },
              {
                headers,
              },
            ),
          )
        }
      } catch (e: any) {
        setError({
          type: e?.type || 'unknown',
          code: e?.code || 500,
          message: e.message || translations['error.default_toast_header'],
          ...(e?.param ? { param: e.param } : {}),
          ...(e?.path ? { path: e.path } : {}),
          timestamp: e.timestamp || new Date().toJSON(),
        })
        throw e
      }
    }
  }

  return {
    isShown,
    isError: !!error,
    isSuccess: !error,
    error,
    message,
    customer,
    customerProfile,
    isLoadingCustomer,
    setIsShown,
    setMessage,
    handleSubmit,
  }
}
