import { Dispatch, useState } from 'react'
import {
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import {
  ErrorResponse,
  ExploreApi,
  ExploreSessionPackage,
  ExploreSessionPackageList,
} from '@citruscamps/citrus-client'
import { DefaultMainPagination, UnlimitedPagination } from '../../../constants/pagination'
import { Pagination } from '../../../interfaces/pagination'
import { generateApiConfig } from '../../../utils/client-config'
import { generateItemKey, generateListKey } from '../../../utils/key-generator'
import { useRequestHandler } from '../../../hooks/useRequestHandler'

export const DefaultSessionPackageFetchProps: SessionPackageFetchProps = {
  sort: 'price',
  order: 'ASC',
  filter: {},
}

export interface SessionPackageFetchProps {
  sort?: string
  order?: 'ASC' | 'DESC'
  filter?: any
}

interface IProps {
  programId?: string
  pagination?: Pagination
  fetchProps?: SessionPackageFetchProps
  overwriteFilters?: boolean
  fetchAll?: boolean
  enabled?: boolean
}

interface FetchSessionPackages {
  data: ExploreSessionPackage[]
  error: ErrorResponse | null
  fetchProps: SessionPackageFetchProps
  isError: boolean
  isLoading: boolean
  pagination: Pagination
  setFetchProps: (value?: SessionPackageFetchProps) => void
  setPagination: Dispatch<Pagination>
}

export const useFetchSessionPackages = ({
  programId,
  pagination: initialPagination = DefaultMainPagination,
  fetchProps: initialFetchProps = DefaultSessionPackageFetchProps,
}: IProps): FetchSessionPackages => {
  const { requestHandler } = useRequestHandler()
  const queryClient = useQueryClient()
  const [pagination, setPagination] = useState<Pagination>(initialPagination)
  const [fetchProps, setFetchProps] = useState<SessionPackageFetchProps>(initialFetchProps)
  const queryKeys = generateListKey({
    type: 'session_package',
    pagination: UnlimitedPagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: { program_id: programId },
  })
  const {
    isInitialLoading: isLoading,
    isError,
    data: resp,
    error,
  } = useQuery<ExploreSessionPackageList, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new ExploreApi(generateApiConfig())
      const skip = pagination.limit ? pagination.page * pagination.limit : undefined
      const response = await requestHandler<ExploreSessionPackageList>(() =>
        client.findSessionPackageExploreSession(
          {
            program_id: programId,
            limit: pagination.limit,
            skip,
            order: fetchProps.order,
            sort: fetchProps.sort,
          },
          { signal },
        ),
      )
      response.data.forEach((item) =>
        queryClient.setQueryData(
          generateItemKey({
            type: 'session_package',
            id: item.id,
          }),
          item,
        ),
      )
      return response
    },
    {
      enabled: !!programId,
    },
  )

  const _setFetchProps = (val?: SessionPackageFetchProps) => {
    setPagination(initialPagination)
    return setFetchProps(val || DefaultSessionPackageFetchProps)
  }

  return {
    data: resp?.data || [],
    error,
    fetchProps,
    isError,
    isLoading,
    pagination: {
      ...pagination,
      count: resp?.count || pagination.count,
      total: resp?.total || pagination.total,
      page: resp?.page || pagination.page,
      page_count: resp?.page_count || pagination.page_count,
    },
    setFetchProps: _setFetchProps,
    setPagination,
  }
}

interface FetchInfiniteSessionPackages {
  data: ExploreSessionPackage[]
  error: ErrorResponse | undefined
  fetchProps: SessionPackageFetchProps
  isError: boolean
  isFetching: boolean
  isFetchingNextPage: boolean
  isLoading: boolean
  hasNextPage: boolean
  pagination: Pagination
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<ExploreSessionPackageList, ErrorResponse>>
  setFetchProps: (value?: SessionPackageFetchProps) => void
}

export const useFetchInfiniteSessionPackages = ({
  programId,
  pagination = DefaultMainPagination,
  fetchAll = false,
  enabled = true,
  fetchProps: initialFetchProps = DefaultSessionPackageFetchProps,
}: IProps): FetchInfiniteSessionPackages => {
  const { requestHandler } = useRequestHandler()
  const queryClient = useQueryClient()
  const [fetchProps, setFetchProps] = useState<SessionPackageFetchProps>(initialFetchProps)
  const queryKeys = generateListKey({
    type: 'session_package',
    pagination: UnlimitedPagination,
    sort: fetchProps.sort,
    order: fetchProps.order,
    query: { program_id: programId },
  })
  const {
    data: resp,
    error,
    isError,
    isInitialLoading: isLoading,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage = false,
  } = useInfiniteQuery<ExploreSessionPackageList, ErrorResponse>(
    queryKeys,
    async ({ signal, pageParam: pg }) => {
      pg = pg || pagination
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new ExploreApi(generateApiConfig())
      const skip = pg.limit ? pg.page * pg.limit : undefined
      const response = await requestHandler<ExploreSessionPackageList>(() =>
        client.findSessionPackageExploreSession(
          {
            program_id: programId,
            limit: pg.limit,
            skip,
            order: fetchProps.order,
            sort: fetchProps.sort,
          },
          { signal },
        ),
      )
      response.data.forEach((item) =>
        queryClient.setQueryData(
          generateItemKey({
            type: 'session_package',
            id: item.id,
          }),
          item,
        ),
      )
      return response
    },
    {
      getNextPageParam: (resp: ExploreSessionPackageList): Pagination | undefined => {
        if (!resp.page_count || resp.page < resp.page_count - 1) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page + 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      getPreviousPageParam: (resp: ExploreSessionPackageList): Pagination | undefined => {
        if (!resp.page_count || resp.page <= resp.page_count) {
          return {
            ...pagination,
            count: resp?.count,
            total: resp?.total,
            page: resp?.page - 1,
            page_count: resp?.page_count,
          }
        }
        return undefined
      },
      enabled: !!programId && !!enabled,
    },
  )
  const _setFetchProps = (val?: SessionPackageFetchProps) => {
    return setFetchProps(val || DefaultSessionPackageFetchProps)
  }
  if (!(isFetching || isFetchingNextPage || isLoading) && hasNextPage && fetchAll) {
    fetchNextPage()
  }

  const response = [...(resp?.pages || [])].pop()

  return {
    data:
      (resp?.pages || []).reduce<ExploreSessionPackage[]>((list, p) => [...list, ...p.data], []) ||
      [],
    error: error || undefined,
    fetchProps,
    hasNextPage,
    isError,
    isFetching,
    isFetchingNextPage,
    isLoading,
    pagination: {
      ...pagination,
      count: response?.count || pagination.count,
      total: response?.total || pagination.total,
      page: response?.page || pagination.page,
      page_count: response?.page_count || pagination.page_count,
    },
    fetchNextPage,
    setFetchProps: _setFetchProps,
  }
}
