import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react'
import { ToastProps } from '../components/Toast/Toast'

export const DefaultToastTimeout = 5000
export const DefaultToastFade = 700

const ToastContext: React.Context<ToastProvider> = createContext<ToastProvider>({
  toast: undefined,
  setToast: async () => {
    return undefined
  },
})

// yummy
export const ProvideToast = ({ children }: React.PropsWithChildren<any>): React.ReactElement => {
  const toast: ToastProvider = useProvideToast()
  return <ToastContext.Provider value={toast}>{children}</ToastContext.Provider>
}

export const useToast = (): ToastProvider => {
  return useContext<ToastProvider>(ToastContext)
}

export interface ToastProvider {
  toast?: ToastProps
  setToast: Dispatch<SetStateAction<ToastProps | undefined>>
}

export const useProvideToast = (): ToastProvider => {
  const [toast, setToast] = useState<ToastProps | undefined>()
  const [timeoutId, setTimeoutId] = useState<number>(0)

  const handleAutoClose = (values?: ToastProps) => {
    const timeout = values?.timeout || DefaultToastTimeout
    const autoClose = typeof values?.autoClose !== 'undefined' ? values?.autoClose : true
    if (autoClose) {
      let interval: number = Math.round(timeout / 500)
      setTimeoutId(
        window.setTimeout(() => {
          setToast(undefined)
        }, interval * 500 + interval + DefaultToastFade),
      )
    }
  }

  const update: Dispatch<SetStateAction<ToastProps | undefined>> = (
    value?: SetStateAction<ToastProps | undefined>,
  ) => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }
    setToast(value)
    handleAutoClose(typeof value === 'object' ? value : undefined)
  }

  return {
    toast,
    setToast: update,
  }
}
