import fetch from 'isomorphic-unfetch'
import { generateBaseUrl } from './client-config'

class HttpError extends Error {
  constructor(message: string, method?: string, code?: number, path?: string, timestamp?: string) {
    super(message)
    this.method = method
    this.code = code || 500
    this.message = message
    this.path = path || ''
    this.name = this.constructor.name
    this.timestamp = timestamp || new Date().toJSON()
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
  }
  method?: string
  code: number
  message: string
  path: string
  timestamp: string
  name: string
  stack?: string
}

export class HttpClient {
  baseUrl: string

  constructor({ baseUrl } = { baseUrl: generateBaseUrl() || '' }) {
    this.baseUrl = baseUrl
  }

  async get<T>(url: string = '', options: RequestInit = { headers: {} }): Promise<T> {
    let url_ = this.baseUrl + url
    url_ = url_.replace(/[?&]$/, '')
    options.headers = options.headers || {}
    const response = await fetch(url_, {
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
    })
    if (response.status >= 400) {
      const err = await response.json()
      throw new HttpError(err.message, 'GET', response.status, url_, err.timestamp)
    }
    return (await response.json()) as T
  }
  async put<T>(
    url: string = '',
    data: any = {},
    options: RequestInit = { headers: {} },
  ): Promise<T> {
    let url_ = this.baseUrl + url
    url_ = url_.replace(/[?&]$/, '')
    options.headers = options.headers || {}
    // Default options are marked with *
    const response = await fetch(url_, {
      method: 'PUT',
      cache: 'no-cache',
      credentials: 'same-origin',
      referrerPolicy: 'no-referrer',
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
      body: JSON.stringify(data),
    })

    if (response.status >= 400) {
      const err = await response.json()
      throw new HttpError(err.message, 'PUT', response.status, url_, err.timestamp)
    }
    return (await response.json()) as T
  }
  async post<T>(
    url: string = '',
    data: any = {},
    options: RequestInit = { headers: {} },
  ): Promise<T> {
    let url_ = this.baseUrl + url
    url_ = url_.replace(/[?&]$/, '')
    options.headers = options.headers || {}
    // Default options are marked with *
    const response = await fetch(url_, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      referrerPolicy: 'no-referrer',
      ...options,
      headers: {
        'Content-Type': 'application/json',
        ...options.headers,
      },
      body: JSON.stringify(data),
    })

    if (response.status >= 400) {
      const err = await response.json()
      throw new HttpError(err.message, 'POST', response.status, url_, err.timestamp)
    }
    return (await response.json()) as T
  }
  async upload<T>(
    url: string = '',
    { file, purpose }: any,
    options: RequestInit = { headers: {} },
  ): Promise<T> {
    let url_ = this.baseUrl + url
    url_ = url_.replace(/[?&]$/, '')
    options.headers = options.headers || {}
    const formData = new FormData()
    formData.set('file', file)
    formData.set('purpose', purpose)
    // Default options are marked with *
    const response = await fetch(url_, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      referrerPolicy: 'no-referrer',
      ...options,
      headers: {
        ...options.headers,
      },
      body: formData,
    })

    if (response.status >= 400) {
      const err = await response.json()
      throw new HttpError(err.message, 'POST', response.status, url_, err.timestamp)
    }
    return (await response.json()) as T
  }
}
