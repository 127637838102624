import { ErrorResponse, ExploreApi, ExploreProgramFeatures } from '@citruscamps/citrus-client'
import { useQuery } from '@tanstack/react-query'
import React, { createContext, useContext } from 'react'
import { useProgram } from '../../../hooks/useFetchProgram'
import { useRequestHandler } from '../../../hooks/useRequestHandler'
import { generateApiConfig } from '../../../utils/client-config'
import { generateBaseKey } from '../../../utils/key-generator'

const FlagContext: React.Context<FlagProvider> = createContext<FlagProvider>({
  flags: undefined,
  isLoading: true,
  isError: false,
})

export const ProvideFlags = ({ children }: React.PropsWithChildren<any>): React.ReactElement => {
  const toast: FlagProvider = useProvideFlags()
  return <FlagContext.Provider value={toast}>{children}</FlagContext.Provider>
}

export const useFlags = (): FlagProvider => {
  return useContext<FlagProvider>(FlagContext)
}

export interface FlagProvider {
  flags?: ExploreProgramFeatures
  isLoading: boolean
  isError: boolean
  error?: ErrorResponse
}

export const useProvideFlags = (): FlagProvider => {
  const { requestHandler } = useRequestHandler()
  const { program } = useProgram()
  const queryKeys = generateBaseKey({
    type: 'features',
    query: {
      program_id: program?.id,
    },
  })
  const {
    data: flags,
    error,
    isError,
    isInitialLoading: isLoading,
  } = useQuery<ExploreProgramFeatures, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      if (!program?.id) {
        throw new Error('Unable to fetch data')
      }
      const client = new ExploreApi(generateApiConfig())
      return await requestHandler<ExploreProgramFeatures>(() =>
        client.findFeaturesExploreProgram({ program_id: program.id }, { signal }),
      )
    },
    { enabled: !!program?.id, keepPreviousData: true },
  )

  return { flags, isLoading, isError, error: error || undefined }
}
