import { InsightEvent } from '../utils/track-utils'

interface ITrackInsightEvent {
  handleTrack: (event: Omit<InsightEvent, 'url'>) => Promise<any>
}

export const useTrackInsightEvent = (): ITrackInsightEvent => {
  const handleTrack = async (partialEvent: Omit<InsightEvent, 'url'>) => {
    const response = await fetch('/api/insights/events', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        url: window.location.href,
        type: partialEvent.type,
        props: partialEvent.props,
      }),
    })
    if (!response.ok) {
      console.error('Failed to track event')
      return
    }
    return response.json()
  }

  return {
    handleTrack,
  }
}
