import { useRouter } from 'next/router'
import React, { createContext, useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ErrorResponse, ExploreApi, ExploreProgram } from '@citruscamps/citrus-client'
import { generateApiConfig } from '../utils/client-config'
import { generateItemKey } from '../utils/key-generator'
import { useRequestHandler } from './useRequestHandler'

const ProgramContext: React.Context<ProgramProvider> = createContext<ProgramProvider>({
  program: undefined,
  isError: false,
  isLoading: false,
})

export const ProvideProgram = ({ children }: React.PropsWithChildren<any>): React.ReactElement => {
  const theme: ProgramProvider = useProvideProgram()
  return <ProgramContext.Provider value={theme}>{children}</ProgramContext.Provider>
}

export const useProgram = (): ProgramProvider => {
  return useContext<ProgramProvider>(ProgramContext)
}
export interface ProgramProvider {
  program?: ExploreProgram
  isError: boolean
  isLoading: boolean
  error?: ErrorResponse
}

export const useProvideProgram = (): ProgramProvider => {
  const { requestHandler } = useRequestHandler()
  const { query } = useRouter()
  const programId: string | undefined =
    typeof query.program_id === 'string'
      ? query.program_id
      : query.program_id
      ? query.program_id[0]
      : undefined
  const queryKeys = generateItemKey({
    type: 'program',
    id: programId,
  })
  const {
    data,
    error,
    isError,
    isInitialLoading: isLoading,
  } = useQuery<ExploreProgram, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      if (!programId) {
        throw new Error('Unable to fetch data')
      }
      const client = new ExploreApi(generateApiConfig())
      return await requestHandler<ExploreProgram>(() =>
        client.findOneExploreProgram({ program_id: programId }, { signal }),
      )
    },
    { enabled: !!programId, keepPreviousData: true },
  )

  return {
    program: data,
    error: error || undefined,
    isError,
    isLoading,
  }
}
