import {
  AuthenticationApi,
  ErrorResponse as IErrorResponse,
  JwtToken,
} from '@citruscamps/citrus-client'
import { useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import React from 'react'
import { ToastProps } from '../components/Toast/Toast'
import { ROUTES } from '../constants/routes'
import translations from '../translations/en.json'
import { generateApiConfig } from '../utils/client-config'
import { toSingleQueryValue } from '../utils/query-params'
import { useLocalStorage } from './useLocalStorage'

interface IHandleUnauthorizedOptions {
  router: ReturnType<typeof useRouter>
  queryClient: ReturnType<typeof useQueryClient>
  redirect?: boolean
}

const handleUnauthorized = async ({
  router,
  queryClient,
  redirect,
}: IHandleUnauthorizedOptions) => {
  if (typeof redirect === 'undefined' || redirect === true) {
    let query: Record<string, string> = {}
    if (window.location.pathname !== ROUTES.LOGOUT) {
      query = { goto: window.location.href }
    }
    if (router.query.program_id) {
      query.program_id = toSingleQueryValue(router.query).program_id
    }
    if (window.location.pathname !== ROUTES.LOGIN) {
      await router.push({
        pathname: ROUTES.LOGIN,
        query,
      })
    }
    await queryClient.clear()
    await queryClient.invalidateQueries()
  }
}

interface IRefreshTokenOptions extends IHandleUnauthorizedOptions {
  localStorage: ReturnType<typeof useLocalStorage<JwtToken | undefined>>
}

let $refreshToken: Promise<JwtToken> | undefined
const fetchRefreshToken = async ({
  localStorage,
  ...options
}: IRefreshTokenOptions): Promise<JwtToken | undefined> => {
  const [jwtToken, setLocalToken] = localStorage
  if ($refreshToken) {
    return await $refreshToken
  }
  try {
    // refresh token
    if (!jwtToken) {
      throw new Error('No token')
    }
    const authClient = new AuthenticationApi(generateApiConfig())
    $refreshToken = authClient.refreshTokenAuth({ JwtToken: jwtToken })
    const newToken = await $refreshToken
    $refreshToken = undefined
    setLocalToken(newToken)
    return jwtToken
  } catch (e) {
    // handle bad token
    setLocalToken(undefined)
    handleUnauthorized(options)
  }
}

interface IErrorResponseOptions extends Partial<IErrorResponse>, Pick<IErrorResponse, 'message'> {
  message: string
  cause?: unknown
}
export class ErrorResponse implements IErrorResponse {
  constructor(options: IErrorResponseOptions) {
    this.code = options.code || 500
    this.message = options.message
    this.type = options.type
    this.param = options.param
    this.path = options.path
    this.timestamp = options.timestamp || new Date().toISOString()
  }

  type?: string
  code: number
  message: string
  param?: string
  path?: string
  timestamp: string
}

interface IRequestHandlerOptions {
  toast?: {
    success?: string
    error?: string
    setToast?: React.Dispatch<React.SetStateAction<ToastProps | undefined>>
  }
  retry?: boolean
  redirect?: boolean
  useToken?: boolean
}

export function useRequestHandler() {
  const router = useRouter()
  const queryClient = useQueryClient()
  const localStorage = useLocalStorage<JwtToken | undefined>('access_token', undefined)
  async function requestHandler<T>(
    request: () => Promise<T>,
    options?: IRequestHandlerOptions,
  ): Promise<T> {
    try {
      const response = await request()
      if (options?.toast?.setToast && options?.toast?.success) {
        options.toast.setToast({
          header: (translations as any)['success.default_toast_header'],
          message: options?.toast?.success,
          type: 'success',
        })
      }
      return response
    } catch (error: any) {
      console.error(error.stack)
      let err: IErrorResponse
      try {
        const response = await error.response.json()
        err = new ErrorResponse({ ...response, code: error.response.status })
      } catch {
        err = new ErrorResponse(error)
      }
      err.code = err.code || 500
      err.message =
        !!err.message &&
        err.message !== 'Failed to fetch' &&
        err.message !== 'Internal server error'
          ? err.message
          : (translations as any)['error.default_toast_message']
      if (err.code === 401 && options?.useToken) {
        await fetchRefreshToken({
          router,
          queryClient,
          redirect: options?.redirect,
          localStorage,
        })
        if (typeof options?.retry === 'undefined' || options?.retry === true) {
          return requestHandler(request, { ...(options || {}), retry: false })
        }
      } else if (err.code === 401) {
        await handleUnauthorized({ router, queryClient, redirect: options?.redirect })
      } else if (err.code === 400) {
        if (options?.toast?.setToast) {
          options.toast.setToast({
            header: (translations as any)['error.default_toast_header'],
            message:
              options?.toast?.error ||
              error.message ||
              (translations as any)['error.default_inputs_message'],
            type: 'danger',
          })
        }
      } else if (err.message === 'Failed to fetch') {
        err.code = 503
      } else if (error.name !== 'AbortError') {
        if (options?.toast?.setToast) {
          options.toast.setToast({
            header: (translations as any)['error.default_toast_header'],
            message: options?.toast?.error || (translations as any)['error.default_toast_message'],
            type: 'danger',
          })
        }
      }
      throw err
    }
  }
  return {
    requestHandler,
  }
}
