import {
  AccountApi,
  AuthenticationApi,
  CreateUser,
  ErrorResponse,
  Login,
  SuccessResponse,
  User,
} from '@citruscamps/citrus-client'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import React, { createContext, useContext } from 'react'
import { generateApiConfig } from '../utils/client-config'
import { generateBaseKey } from '../utils/key-generator'
import { useRequestHandler } from './useRequestHandler'
import { useTrackInsightEvent } from './useTrackInsightEvent'

const DefaultContext: AuthValue = {
  isLoading: true,
  isFetched: false,
  isError: false,
  isSuccess: true,
  error: null,
  handleLogin: async () => {
    throw new Error('Method not initialized.')
  },
  handleSignUp: async () => {
    throw new Error('Method not initialized.')
  },
  handleUpdateUser: async () => {
    throw new Error('Method not initialized.')
  },
  handleLogout: async () => {
    throw new Error('Method not initialized.')
  },
}

const authContext: React.Context<AuthValue> = createContext<AuthValue>(DefaultContext)

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().

export const ProvideAuth = ({ children }: React.PropsWithChildren<any>): React.ReactElement => {
  const auth: AuthValue = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.

export const useAuth = (): AuthValue => {
  return useContext<AuthValue>(authContext)
}

// Provider hook that creates auth object and handles state

export interface AuthValue {
  user?: User
  isLoading: boolean
  isFetched: boolean
  isError: boolean
  isSuccess: boolean
  error: ErrorResponse | null
  handleLogin: (values: Login) => Promise<void>
  handleSignUp: (values: CreateUser) => Promise<void>
  handleUpdateUser: (values: User) => Promise<User>
  handleLogout: () => Promise<void>
}

export const useProvideAuth = (): AuthValue => {
  const { requestHandler } = useRequestHandler()
  const queryClient = useQueryClient()
  const { handleTrack } = useTrackInsightEvent()
  const {
    data: user,
    error,
    isInitialLoading: isLoading,
    isFetched,
    isError,
    isSuccess,
  } = useQuery<User, ErrorResponse>(
    generateBaseKey({ type: 'user' }),
    async ({ signal }) => {
      const accountClient = new AccountApi(generateApiConfig())
      const _user = await requestHandler<User>(
        () => accountClient.findByCurrentUserAccount({ signal }),
        {
          redirect: false,
        },
      )
      return _user
    },
    {
      retry: false,
    },
  )

  const handleLogin = async (values: Login): Promise<void> => {
    const authClient = new AuthenticationApi(generateApiConfig())
    await requestHandler<SuccessResponse>(() => authClient.loginAuth({ Login: values }), {
      retry: false,
    })
    await Promise.allSettled([
      handleTrack({
        type: 'login',
        props: {},
      }),
    ])
    await queryClient.invalidateQueries(generateBaseKey({ type: 'user' }))
  }

  const handleSignUp = async (createUser: CreateUser): Promise<void> => {
    const accountClient = new AccountApi(generateApiConfig())
    const user = await requestHandler<User>(
      () =>
        accountClient.signupAccount({
          CreateUser: {
            ...createUser,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        }),
      {
        retry: false,
        redirect: false,
      },
    )
    await Promise.allSettled([
      handleTrack({
        type: 'sign_up',
        props: {},
      }),
    ])
    await queryClient.setQueryData(generateBaseKey({ type: 'user' }), user)
  }

  const handleUpdateUser = async (value: User): Promise<User> => {
    const accountClient = new AccountApi(generateApiConfig())
    const _user = await requestHandler<User>(() =>
      accountClient.updateCurrentUserAccount({ User: value }),
    )
    await queryClient.invalidateQueries(generateBaseKey({ type: 'user' }))
    return _user
  }

  const handleLogout = async (): Promise<void> => {
    const authClient = new AuthenticationApi(generateApiConfig())
    try {
      await requestHandler<SuccessResponse>(() => authClient.logoutAuth())
      await queryClient.clear()
    } catch (e: any) {
      await queryClient.clear()
      throw e
    }
  }

  return {
    user,
    isLoading,
    isFetched,
    isError,
    isSuccess,
    error,
    handleLogin,
    handleSignUp,
    handleUpdateUser,
    handleLogout,
  }
}
