import { useQuery } from '@tanstack/react-query'
import { Customer, CustomerApi, ErrorResponse } from '@citruscamps/citrus-client'
import { useAuth } from '../../../hooks/useAuth'
import { generateApiConfig } from '../../../utils/client-config'
import { generateBaseKey } from '../../../utils/key-generator'
import { useRequestHandler } from '../../../hooks/useRequestHandler'

interface IProps {
  redirect?: boolean
}

interface FetchedData {
  data?: Customer
  isSuccess: boolean
  isLoading: boolean
  isError: boolean
  error: ErrorResponse | null
}

export const useFetchCustomer = ({ redirect }: IProps): FetchedData => {
  const { requestHandler } = useRequestHandler()
  const { user } = useAuth()
  const queryKeys = generateBaseKey({ type: 'customer' })
  const {
    data,
    error,
    isSuccess,
    isError,
    isInitialLoading: isLoading,
  } = useQuery<Customer, ErrorResponse>(
    queryKeys,
    async ({ signal }) => {
      const client = new CustomerApi(generateApiConfig())
      return await requestHandler<Customer>(() => client.findOneCustomer({ signal }), {
        redirect: typeof redirect === 'undefined' || redirect === true,
      })
    },
    { enabled: !!user?.id, refetchOnWindowFocus: false },
  )

  return {
    data,
    isSuccess,
    isLoading,
    isError,
    error,
  }
}
