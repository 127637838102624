import { Configuration, ConfigurationParameters } from '@citruscamps/citrus-client'
import fetch from 'isomorphic-unfetch'
import { getLocalStorageItem } from './local-storage'

const safeGetLocalStorageItem = (key: string): any | undefined => {
  try {
    return getLocalStorageItem(key)
  } catch (error) {
    console.error('Error getting access token from local storage', error)
  }
}

export const generateBaseUrl = () => {
  const isServer = typeof window === 'undefined'
  const host = process.env.API_SERVICE_HOST
  const port = process.env.API_SERVICE_PORT
  if (isServer && host && port) return `http://${host}:${port}`
  return process.env.REACT_APP_API_BASE_URL
}

export const generateApiConfig = (configuration: ConfigurationParameters = {}) => {
  if (typeof window !== 'undefined') {
    configuration.accessToken = safeGetLocalStorageItem('access_token')?.access_token
  }
  configuration.basePath = generateBaseUrl()
  configuration.fetchApi = fetch
  configuration.credentials = 'include'
  return new Configuration(configuration)
}
