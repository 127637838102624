import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'

const CitrusRouterContext: React.Context<CitrusRouterProvider> =
  createContext<CitrusRouterProvider>({
    pathname: '',
    hostname: process.env.REACT_APP_BASE_URL || '',
  })

export const ProvideCitrusRouter = ({
  children,
  ...props
}: React.PropsWithChildren<IProps>): React.ReactElement => {
  const toast: CitrusRouterProvider = useProvideCitrusRouter(props)
  return <CitrusRouterContext.Provider value={toast}>{children}</CitrusRouterContext.Provider>
}

export const useCitrusRouter = (): CitrusRouterProvider => {
  return useContext<CitrusRouterProvider>(CitrusRouterContext)
}

interface IProps {
  pathname: string
  hostname: string
}

export interface CitrusRouterProvider {
  pathname: string
  hostname: string
}

export const useProvideCitrusRouter = (props: IProps): CitrusRouterProvider => {
  const nextRouter = useRouter()
  const [{ pathname, hostname }, setValue] = useState<IProps>(props)

  useEffect(() => {
    setValue({ hostname: props.hostname, pathname: props.hostname + nextRouter.asPath })
  }, [props.hostname, nextRouter.asPath])

  return {
    pathname,
    hostname,
  }
}
